import React from "react"

import SectionTemplate from "../Template/sectionTemplate"
import { AiOutlineLink } from 'react-icons/ai'

const Qualifications = ({ className }) => {
  const qualifications = [
    {
      title: "Amazon Web Services (AWS)",
      course: "Certified Solutions Architect - Associate",
      period: "May 2020",
      link: "https://www.youracclaim.com/badges/e75cde87-838a-46fd-8f7d-3a353a71f6a0"
    },
    {
      title: "Republic Polytechnic",
      course: "Diploma in Information Technology",
      period: "2010 - 2013",
      content: (<>GPA - 3.46 <br/> Academic Roll of Honour - 2011</>),
    },
  ]

  return (
    <SectionTemplate id="qualifications" title="Qualifications" className={className}>
      {
        qualifications.map((qualification, i) => (
          <div key={i}>
            <h3>{qualification.title}</h3>
            <p className="text-muted">
              {qualification.course} | {qualification.period} {" "}
              {
                qualification.link ?
                <a href={qualification.link} target="_blank" rel="noopener noreferrer">
                  <AiOutlineLink size="1.2rem" />
                </a> : ""
              }
            </p>
            {qualification.content ? <p>{qualification.content}</p> : ""}
          </div>
        ))
      }
    </SectionTemplate>
  )
}

export default Qualifications

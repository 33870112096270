import React from "react"

import classes from './section.module.css'

const SectionTemplate = ({id, title, children, className}) => {

  return (
    <section id={id} className={`${classes.section} ${className}`}>
      {
        title ?
        <div className={classes.sectionHeader}>
          <h2>{title}</h2>
        </div> : ""
      }

      <div className={classes.sectionBody}>
        {children}
      </div>
    </section>
  )
}

export default SectionTemplate

import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import SEO from '../components/seo'
import Nav from '../components/Resume/Nav/nav'
import Heading from '../components/Resume/Heading/heading'
import Summary from '../components/Resume/Summary/summary'
import Skills from '../components/Resume/Skills/skills'
import Experience from "../components/Resume/Experience/experience"
import Projects from "../components/Resume/Projects/projects"
import Qualifications from "../components/Resume/Qualifications/qualifications"
import Interest from "../components/Resume/Interest/interest"
import Contact from "../components/Resume/Contact/contact"
import Footer from "../components/Resume/Footer/footer"

import classes from '../components/Resume/resume.module.css'

const Resume = () => {

  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: {eq: "Alvin Choong - Resume.pdf"}) {
          publicURL
        }
      }
    `
  )

  return (
    <div className={`${classes.resume}`}>
      <SEO title="Resume" />

      <Container className="p-0 h-100 bg-white" fluid="lg">
        <Row className="h-100 m-0">
          <Col md="12" lg="auto" className="p-0 d-print-none">
            <Nav file={file}/>
          </Col>
          <Col>
            <Heading className="d-none d-print-block" />
            <hr className="m-0 d-none d-print-block" />
            
            <Summary />
            <hr className="m-0" />

            <Skills />
            <hr className="m-0" />

            <Experience />
            <hr className="m-0" />

            <Projects />
            <hr className="m-0" />

            <Qualifications />
            <hr className="m-0" />

            <Interest />
            <hr className="m-0 d-print-none" />

            <Contact className="d-print-none" />
            <hr className="m-0 d-print-none" />
            
            <Footer className="d-print-none"/>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Resume
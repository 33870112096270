import React from "react"

import SectionTemplate from "../Template/sectionTemplate"
import { FaLinkedin, FaGlobeAsia } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
// import { IoMdPhonePortrait } from 'react-icons/io'

import classes from './heading.module.css'

const Heading = ({ className }) => {
  
  return (
    <SectionTemplate id="heading" className={className}>
      <div className={`${classes.heading} d-flex justify-content-between`}>
        <div>
          <h1>Alvin<br/>Choong</h1> 
        </div>
        <div className="d-flex align-items-end">
          <ul className={`text-right list-unstyled pl-0 mb-0`}>
            <li>
              <a href="https://linkedin.com/in/alvinchoong" target="_blank" rel="noopener noreferrer">
                linkedin.com/in/alvinchoong
                &nbsp;<FaLinkedin />
              </a>
            </li>
            <li>
              <a href="https://alvinc.me" target="_blank" rel="noopener noreferrer">
                alvinc.me
                &nbsp;<FaGlobeAsia />
              </a>
            </li>
            <li>
              <a href="mailto:alvin.chooong92@gmail.com">
                alvin.chooong92@gmail.com
                &nbsp;<FiMail />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </SectionTemplate>
  )
}

export default Heading
import React from "react"

import SectionTemplate from "../Template/sectionTemplate"

import ContactForm from '../../Form/contactForm'

import classes from './contact.module.css'

const Contact = ({ className }) => {
  return (
    <SectionTemplate id="contact" title="Contact" className={className}>
      <div>
        <ContactForm formClass={classes.contactForm} />
      </div>

      <div>
        Or simply contact me at <a href="mailto:alvin.choong92@gmail.com">alvin.choong92@gmail.com</a>
      </div>
    </SectionTemplate>
  )
}

export default Contact
import React from "react"
import Helmet from 'react-helmet'

import SectionTemplate from '../Template/sectionTemplate'
import classes from './skills.module.css'

import { GoPrimitiveDot } from 'react-icons/go';

const Skills = ({ className }) => {
  const techIconClass = [
    "devicon-javascript-plain",
    "devicon-python-plain-wordmark",
    "devicon-php-plain",
    "devicon-nodejs-plain",
    "devicon-express-original",
    "devicon-laravel-plain-wordmark",
    "devicon-react-original-wordmark",
    "devicon-mysql-plain-wordmark",
    "devicon-mongodb-plain-wordmark",
    "devicon-amazonwebservices-original",
  ];

  const techList = [
    "Javascript",
    "Python",
    "PHP",
    "Node.js",
    "Express",
    "React",
    "Gatsby.js",
    "Laravel",
    "MySQL",
    "MongoDB",
    "AWS Cloud",
  ];

  const softSkills = [
    'Problem Solving',
    'Learning Agility',
    'Creative',
    'Critical Thinking',
    'People Management',
    'Leadership',
    'Entrepreneur',
  ];

  return (
    <SectionTemplate id="skills" title="Skills" className={className}>
      <Helmet>
        <link rel="stylesheet" href="https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css" />
      </Helmet>
      <div>
        <h3>Tech</h3>
        <ul className={`${classes.iconList} list-inline mt-2 d-print-none`}>
          {
            techIconClass.map(cn => (
              <li className="list-inline-item" key={cn}>
                <i className={cn}></i>
              </li>
            ))
          }
        </ul>
        
        <ul className={`${classes.bulletList} list-inline pl-0 d-none d-print-block`}>
          {
            techList.map(tech => (
              <li className="list-inline-item" key={tech}>
                <GoPrimitiveDot /> {tech}
              </li>
            ))
          }
        </ul>
      </div>

      <div>
        <h3>Soft Skills</h3>
        <ul className={`${classes.bulletList} list-inline pl-0`}>
          {
            softSkills.map(ss => (
              <li className="list-inline-item">
                <GoPrimitiveDot /> {ss}
              </li>
            ))
          }
        </ul>
      </div>
    </SectionTemplate>
  )
}

export default Skills
import React from "react"

import SectionTemplate from "../Template/sectionTemplate"

import { FaLinkedin, FaGlobeAsia } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

import classes from './footer.module.css'

const Footer = ({ className }) => {

  return (
    <SectionTemplate id="footer" className={className}>
      <div>
        <ul className={`${classes.footerList} text-right list-inline pl-0 mb-0`}>
          <li className="list-inline-item">
            <a href="https://linkedin.com/in/alvinchoong" target="_blank" rel="noopener noreferrer">
              <FaLinkedin/>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="https://alvinc.me" target="_blank" rel="noopener noreferrer">
              <FaGlobeAsia />
            </a>
          </li>
          <li className="list-inline-item">
              <a href="mailto:alvin.chooong92@gmail.com">
                <FiMail />
              </a>
          </li>
        </ul>
      </div>
    </SectionTemplate>
  )
}

export default Footer

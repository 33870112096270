import React from "react"

import SectionTemplate from "../Template/sectionTemplate"

const Summary = ({ className }) => {
  const summary =
    "I have led and built JuzTalent to a well known multi country HRMS that consist of 5 modules. JuzTalent is also the 2019 Gold Award winner for Best HR Tech and has helped many multinational companies to improve their productivity and staying compliant."
    
  return (
    <SectionTemplate id="summary" title="Summary" className={className}>
      <div>
        <p>
          {summary}
        </p>
      </div>
    </SectionTemplate>
  )
}

export default Summary

import React from "react"

import SectionTemplate from "../Template/sectionTemplate"

const Interest = ({ className }) => {
  const interests = [
    'Passionate about software development and cloud technologies',
    'Avid reader for personal finance books',
    'Tinkering with SBC - Raspberry Pi',
    'Sports - Badminton'
  ];
  return (
    <SectionTemplate id="interest" title="Interest" className={className}>
      <div>
        <ul className="pl-4">
          {
            interests.map(interest => (
              <li key={interest}>{interest}</li>
            ))
          }
        </ul>
      </div>
    </SectionTemplate>
  )
}

export default Interest
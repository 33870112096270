import React from "react"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import { FiDownload } from 'react-icons/fi'

import classes from './nav.module.css'

const MainNav = ({file}) => {
  const goToSection = (e, page) => {
    e.preventDefault();
    document.querySelector(`#${page}`).scrollIntoView({
      behavior: 'smooth',
      duration: 700
    });
  }

  return (
    <Navbar
      expand="lg"
      bg="dark"
      variant="dark"
      className={classes.sideBar}
      collapseOnSelect
    >
      <div className={classes.btnWrapper}>
        <Navbar.Brand onClick={(e) => goToSection(e, "summary")} href="#summary" className={classes.navbarBrand}>
          <span className="d-block">Alvin Choong</span>
        </Navbar.Brand>

        <div>
          {
            file ?
            <a href={file.publicURL} className={classes.dl} target="_blank" rel="noopener noreferrer">
              <FiDownload />
            </a> : ""
          }

          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="border-0"/>
        </div>
      </div>
      
      <Navbar.Collapse id="responsive-navbar-nav" className={classes.navbarCollapse}>
        <Nav className={classes.navbarNav}>
          <Nav.Link onClick={(e) => goToSection(e, "summary")} href="#summary">Summary</Nav.Link>
          <Nav.Link onClick={(e) => goToSection(e, "skills")} href="#skills">Skills</Nav.Link>
          <Nav.Link onClick={(e) => goToSection(e, "experience")} href="#experience">Experience</Nav.Link>
          <Nav.Link onClick={(e) => goToSection(e, "projects")} href="#projects">Projects</Nav.Link>
          <Nav.Link onClick={(e) => goToSection(e, "qualifications")} href="#qualifications">Qualifications</Nav.Link>
          <Nav.Link onClick={(e) => goToSection(e, "interest")} href="#interest">Interest</Nav.Link>
          <Nav.Link onClick={(e) => goToSection(e, "contact")} href="#contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNav
import React from "react"

import SectionTemplate from "../Template/sectionTemplate"

const Projects = ({ className }) => {
  const projects = [
    {
      title: "Personal Website",
      content: [
        "Built using Gatsby.js",
        "Host using AWS S3 and CloudFront",
        "Built a serverless contact form using Lambda and SES on the Serverless Framework",
        "Designed a print friendly resume page",
        "Automate generating of resume in PDF using puppeteer at build time",
        "Continuous deployment using CodeBuild",
      ],
    },
    {
      title: "Reddit Post to Video",
      content: [
        "Built using Python",
        "Scrap subreddit of choice and store data in MongoDB",
        "Render text into a series of html(sentence break) with template and convert html to image",
        "Generate narration using Google TTS",
        "Stitch images and narration into a video clip",
        "Generate subtitle file(srt) automatically based on text and audio length",
      ],
    },
    {
      title: "Crypto Price Alert with Telegram",
      content: [
        "Cron jobs to store crypto prices(ETH / BTC) using gemini api",
        "Built an Telegram bot",
        "Telegram will send message when there is a price swing of more than 10% in last 5 mins",
        "Added Telegram commands to",
        [
          "Get prices of ETH / BTC",
          "Basic stats, e.g high / low for last 7 days",
        ]
      ],
    },
    {
      title: "Smart Home",
      content: [
        "Centralize control of all smart devices(zigbee / wifi) using HomeAssistant and Raspberry Pi",
        "Automation",
        [
          "Trigger hallway lights based on motion sensor",
          "Trigger store room light based on door sensor",
          "Water sensors to detect water leakage",
          "Turning off a/c unit at a certain time",
        ],
        "Control",
        [
          "Light switches using zigbee2mqtt and CC2531",
          "fan unit using rf blaster",
          "a/c unit using ir blaster",
        ]
      ],
    }
  ];

  const generatePoints = (points, className = "pl-4") => {
    return (
      points ?
        <ul className={className}>
          {
            points.map((point, i) => {
              return (
                Array.isArray(point) ?
                generatePoints(point, "pl-2") : <li key={i}>{point}</li>
              )
            })
          }
        </ul> : ""
    );
  }

  return (
    <SectionTemplate id="projects" title="Pet Projects" className={className}>
      {
        projects.map((project, i) => (
          <div key={i}>
            <h3>{project.title}</h3>
            {generatePoints(project.content)}
          </div>
        ))
      }
    </SectionTemplate>
  )
}

export default Projects
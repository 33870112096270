import React from "react"

import SectionTemplate from "../Template/sectionTemplate"

const Experience = ({ className }) => {
  const experiences = [
    {
      title: "Senior Manager, Technology",
      company: "AYP Connect Pte Ltd",
      period: "Jan 2019 - Present",
      content: [
        "Gathered information, led and developed",
        ["Cambodia payroll application"],
        "Project manage",
        [
          "Redevelopment of eCore, eTime and ePayroll (PH) application - outsource",
          "Integration between existing system and new system",
          "Rewrite of frontend using React",
        ],
        "Re-architect and decoupling of the system",
        "Refactor, extract and rewrite existing functions in Node.js",
      ],
    },
    {
      title: "Manager, Technology",
      company: "AYP Connect Pte Ltd",
      period: "Jan 2018 - Dec 2018",
      content: [
        "Gathered information, led and developed",
        ["Vietnam payroll application"],
        "Led payroll migration project of 15,000 users",
        "Built Customer Success team to provide after sales and product support",
        "Implemented feedback loop for communication between the development, marketing and customer success team",
      ],
    },
    {
      title: "Team Lead, Technology",
      company: "AYP Connect Pte Ltd",
      period: "Jun 2016 - Dec 2017",
      content: [
        "Gathered information, led and developed",
        [
          "eClaim, a digital claim processing and disbursement application",
          "eProfile, a eco friendly p-file and employee directory application",
          "eTime, a time and attendance management application",
          "Indonesia payroll application",
          "Philippines payroll application",
        ],
        "Provide on the job training and development for junior developers",
        "Implemented JIRA with Slack integration as a kanban project management tool to track feature requests and bugs",
      ],
    },
    {
      title: "Web Developer",
      company: "AYP Connect Pte Ltd",
      period: "Feb 2016 - May 2016",
      content: [
        "Developed", 
        ["eLeave, a leave processing and management application"],
        "Testing of SG payroll application to ensure the accuracy of statutory contributions",
        "Built and formed a team of 3 developers",
        "Train sale executive on system functionality",
      ],
    },
  ];

  const generatePoints = (points, className = "pl-4") => {
    return (
      points ? 
      <ul className={className}>
        {
          points.map((point, i) => {
            return (
              Array.isArray(point) ?
              generatePoints(point, "pl-2") : <li key={i}>{point}</li>
            )
          })
        }
      </ul> : ""
    );
  }

  return (
    <SectionTemplate id="experience" title="Experience" className={className}>
      {
        experiences.map((exp, i) => (
          <div key={i}>
            <h3>{exp.title}</h3>
            <p className="text-muted">
              {exp.company} | {exp.period}
            </p>
            { generatePoints(exp.content) }
          </div>
        ))
      }
      
    </SectionTemplate>
  )
}

export default Experience
